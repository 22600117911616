class SocketBase  {
    constructor({socket, backend, dom, streamUserId = null}) {
        this.socket = socket
        this.backend = backend
        this.dom = dom
        this.streamUserId = streamUserId
        this.hooks = []
        this.listenForMessagesFromRobot()
        this.init()
    }

    robotBackendId(){
        return this.backend.robot_backend_id
    }

    on(action, callback) {
        this.hooks.push({
            action: action,
            callback: callback
        })
    }

    do(action) {
        this.hooks.forEach(hook => {
            if(hook.action === action){
                hook.callback();
            }
        })
    }

    init(){

    }

    terminate() {

    }

    listenForMessagesFromRobot() {
        this.socket.on(`r2cmr_${this.backend.robot_backend_id}`, dataPack => {
            this.extractAnyTelemetryData(dataPack)
            this.handleDataPacket(dataPack)
        })
    }

    extractAnyTelemetryData(dataPack) {
        if(dataPack.channel === "log"){
            // console.log(dataPack)
        }
        if(dataPack.channel === "log" && dataPack.chunk && dataPack.chunk.message){
            this.appendTemporaryLogData(`${this.strip(dataPack.chunk.timestamp)}: ${this.strip(dataPack.chunk.message)}`)
        }else if(dataPack.channel === "telemetry" && dataPack.chunk){
            this.appendTemporaryLogData(`${this.strip(dataPack.chunk.timestamp)}: ${this.strip(dataPack.chunk.key)}: ${this.strip(dataPack.chunk.value)}`)
        }
    }

    handleDataPacket(dataPack) {

    }

    sendDataToBackend(data) {
        this.socket.emit(`cmr2r_${this.backend.robot_backend_id}`, data)
    }

    async sendDataToBackendAck(data) {
        return await this.socket.emitWithAck(`cmr2r_${this.backend.robot_backend_id}`, data)
    }

    setupGenericImageDomWrappers() {
        this.imageDomWrapper = document.createElement('div')
        this.imageDomWrapper.className = "absolute inset-0"

        this.logDom = document.createElement('div')
        this.logDom.className = "absolute top-2 left-2 text-xs font-mono group-[.is-minimised]:hidden"

        this.imageDomTag = document.createElement('img')
        this.imageDomTag.className = "w-full h-full object-center object-cover"

        this.imageDomWrapper.appendChild(this.logDom)
        this.imageDomWrapper.appendChild(this.imageDomTag)
    }

    strip(val) {
        return typeof val === 'string' ? val.replace(/[^a-zA-Z0-9_.:=-]/g, ' ') : parseInt(val)
    }

    appendTemporaryLogData(logString) {
        if(!this.logDom)return
        const logEntry = document.createElement('div')
        logEntry.innerText = this.strip(logString)
        this.logDom.prepend(logEntry)
        setTimeout(() => {
            // todo: nice fade out thing?
            this.logDom.removeChild(logEntry)
        }, 2000)
    }
}

export default SocketBase
