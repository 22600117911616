import SocketBase from "./socket"
// import JMuxer from "jmuxer"

class SocketVideoH264 extends SocketBase {
    init(){
        // We append a brand new video tag to our dom node
        this.videoDomTag = document.createElement('video')
        this.videoDomTag.className = "w-full h-full object-center object-cover"
        this.dom.appendChild(this.videoDomTag)
        this.startJmuxer()
    }

    terminate() {
        this.jmuxer.destroy()
        this.dom.removeChild(this.videoDomTag)
    }

    startJmuxer () {
        this.jmuxer = new JMuxer({
            debug: false,
            mode: 'video',
            flushingTime: 0,
            node: this.videoDomTag
        })
    }


    handleDataPacket(dataPack) {
        if(!dataPack.channel && data.chunk.byteLength > 0){
            // Default channel is rendering the data
            this.jmuxer.feed({
                video: new Uint8Array(data.chunk),
            })
        }
    }
}

export default SocketVideoH264
