import SocketBase from "./socket"

class SocketVideoJpeg extends SocketBase {
    init() {
        this.setupGenericImageDomWrappers()
        this.dom.appendChild(this.imageDomWrapper)
    }

    terminate() {
        this.dom.removeChild(this.imageDomWrapper)
    }

    displayFrame(data) {
        if(!data)return
        const framecheck = new Uint8Array(data)
        if(framecheck[0] === 0xff && framecheck[1] === 0xd8 ){
            const frame = URL.createObjectURL(new Blob([data], {type: 'image/jpeg'}))
            this.imageDomTag.src = frame
            setTimeout(() => {
                URL.revokeObjectURL(frame)
            }, 500)
        }
    }

    handleDataPacket(dataPack) {
        if(!dataPack.channel){
            // Default channel is rendering the data
            this.displayFrame(dataPack.chunk)
        }
    }
}

export default SocketVideoJpeg
