import SocketBase from "./socket"

class SocketQuest extends SocketBase {
    init() {
        // add any quests from the initial backend api response
        this.availableQuest = null
        if(this.backend.quest_data){
            this.availableQuest = this.backend.quest_data
        }
        this.buildQuestUi()
    }

    buildQuestUi() {
        // start from scratch again
        if(this.questDom) {
            this.dom.removeChild(this.questDom)
        }

        this.questDom = document.createElement('div')
        this.questDom.className = "absolute overflow-hidden inset-0 p-2"

        // this.logDom = document.createElement('div')
        // this.logDom.className = "p-2 text-xs font-mono"
        // this.questDom.appendChild(this.logDom)

        if(this.availableQuest) {
            this.individualQuest = document.createElement('div')
            this.individualQuest.className = "p-2"
            const singleQuestTitle = document.createElement('div')
            singleQuestTitle.innerText = `This robot has ${this.availableQuest.available_credits} credits to give away. Quest: ${this.strip(this.availableQuest.name)} in ${parseInt(this.availableQuest.quest_duration_minutes,10)} minutes has ${this.availableQuest.steps.length} steps:`
            this.individualQuest.appendChild(singleQuestTitle)
            this.availableQuest.steps.forEach(step => {
                const singleQuestStep = document.createElement('div')
                singleQuestStep.innerText = `Step: ${this.strip(step.name)} completed: ${step.completed ? "YES" : "no"}`
                this.individualQuest.appendChild(singleQuestStep)
            })

            this.questDom.appendChild(this.individualQuest)
        }


        this.dom.appendChild(this.questDom)
    }

    terminate() {
        this.dom.removeChild(this.questDom)
    }

    handleDataPacket(dataPack) {
        if(dataPack.channel && dataPack.channel === "quest" && dataPack?.api_data?.quest_step_completed && dataPack.api_data.messages_for_users){
            dataPack.api_data.messages_for_users.forEach(m => {
                if(m.user_id === this.streamUserId){
                    /*
                      timestamp:
                      user_id:
                      message:
                      credit_amount:
                      quest_id:
                      quest_name:
                      full_quest_completed:
                      seconds_remaining:
                     */
                    // todo: fetch this updated status from our API rather than getting pushed through a socket message:
                    if(m.quest_id && m.quest_step_id){
                        // update the status of the step in the UI to completed
                        if(this.availableQuest && this.availableQuest.quest_id == m.quest_id){
                            this.availableQuest.steps.forEach((step,index) => {
                                if(step.step_id === m.quest_step_id) {
                                    this.availableQuest.steps[index].completed = true
                                }
                            })
                            this.buildQuestUi()
                        }
                    }
                    // const logEntry = document.createElement('div')
                    // logEntry.innerText = `DEBUG: ${this.strip(m.timestamp)}: ${this.strip(m.message)}`
                    // this.logDom.prepend(logEntry)
                }
            })
        }
    }
}

export default SocketQuest
