import SocketBase from "./socket"
import JoyStick from "./joy"

class SocketControl extends SocketBase {
    init() {
        // We append a brand new control tag to our dom node
        this.controlDom = document.createElement('div')
        this.controlDom.className = ""
        this.dom.appendChild(this.controlDom)
        // this.logDom = document.createElement('div')
        // this.logDom.className = "absolute top-0 right-0 font-mono text-xs max-h-6 overflow-hidden"
        // this.controlDom.appendChild(this.logDom)
        // Depending on the type of button this is, we do different things:
        console.log('rending button type',this.backend?.backend_metadata?.type)
        switch(this.backend?.backend_metadata?.type){
            case 'joystick':
                this.startJoyStick()
                break;
            case 'button':
                this.renderButton()
                break;
        }
    }

    terminate() {
        this.dom.removeChild(this.controlDom)
    }

    renderButton() {
        this.buttonDom = document.createElement('div')
        this.buttonDom.className = ""
        this.controlDom.appendChild(this.buttonDom)

        this.button = document.createElement('button')
        this.button.className = "px-4 py-2 text-sm bg-cyan-500 text-white rounded-full shadow-sm"
        this.button.innerText = `${this.backend.backend_metadata.label} (${this.backend.backend_metadata.credits} credits)`
        this.button.addEventListener("click", async (e) => {
            // shoot a message through to the robot letting them know a custom action is incoming.
            const response = await this.sendDataToBackendAck({
                action: 'special_button',
                message: this.backend.backend_metadata.message
            })
            console.log(response)
        })
        this.buttonDom.appendChild(this.button)
    }

    startJoyStick(){
        this.joystickDom = document.createElement('div')
        this.joystickDom.className = "joystick"
        this.controlDom.appendChild(this.joystickDom)

        JoyStick(this.joystickDom)
        this.joystickDom.addEventListener("change", (e) => {
            this.joyStickPosition(e.detail.cardinal)
        });
    }

    startJoyStickOld(){
       // find our input buttons
       new JoyStick(this.joystickDom,{
           // The ID of canvas element
           title: 'control-joystick',
           // width/height
           width: 200,
           height: 200,
           // Internal color of Stick
           internalFillColor: '#00AA00',
           // Border width of Stick
           internalLineWidth: 2,
           // Border color of Stick
           internalStrokeColor: '#003300',
           // External reference circonference width
           externalLineWidth: 2,
           //External reference circonference color
           externalStrokeColor: '#008000',
           // Sets the behavior of the stick
           autoReturnToCenter: true
       },(stickStatus) => {
           this.joyStickPosition(stickStatus.cardinalDirection)
       });
   }

    handleDataPacket(dataPack) {
        console.log('Control received:', dataPack)
    }

   buttonClick(event) {
       const buttonAction = event.target.dataset.action
       console.log('button clicked', event.target, buttonAction)

       this.sendDataToBackend({
           action: 'control',
           message: buttonAction
       })
   }

    joyStickPosition(direction) {
        if(this.lastJoystickDirection !== direction) {
            this.lastJoystickDirection = direction

            this.sendDataToBackend({
                action: 'control',
                message: 'joystick',
                direction: direction
            })
        }
    }
}

export default SocketControl
