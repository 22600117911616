import { Controller } from "@hotwired/stimulus"
import { io } from "socket.io-client"
import SocketVideoH264 from "../lib/socket-video-h264"
import SocketVideoJpeg from "../lib/socket-video-jpeg"
import SocketVideoSvg from "../lib/socket-video-svg"
import SocketTelemetry from "../lib/socket-telemetry"
import SocketControl from "../lib/socket-control"
import SocketQuest from "../lib/socket-quest"

export default class extends Controller {
    static targets = [
        "connectButton", "uiTemplate", "ui", "uiWrapper",
        "queue",
        "backendVideos", "backendTelemetries", "backendControls", "backendQuests", "backendMainControls"
    ]
    static values = {
        id: String
    }

    connect() {
        this.initializedHosts = []
        this.initializedBackends = []
        this.doneSetup = false
        this.fullscreenchanged = this.fullscreenchanged.bind(this)
        this.currentRobotData = null
    }

    disconnect() {
        this.connectButtonTarget.textContent = "Connect"
        this.closeFullscreen()
        this.doneSetup = false
        this.initializedHosts.forEach(host => {
            host.disconnect() // this terminates the socket.io client connection
        })
        this.initializedHosts = []
        this.initializedBackends.forEach(backend => {
            backend.terminate()
        })
        this.queueTarget.innerHTML = ""
        this.initializedBackends = []
        this.currentRobotData = null

        // for now we're just hard reloading the page so the full UI can change to a "Robot Offline" view
        // will fix that when we move to react.
        window.location.reload()
    }

    fullscreen() {
        if(!this.doneSetup){
            this.setup()
        }
        this.uiWrapperTarget.removeEventListener("fullscreenchange", this.fullscreenchanged);
        this.uiWrapperTarget.addEventListener("fullscreenchange", this.fullscreenchanged);
        if (this.uiWrapperTarget.requestFullscreen) {
            this.uiWrapperTarget.requestFullscreen();
        } else if (this.uiWrapperTarget.webkitRequestFullscreen) { /* Safari */
            this.uiWrapperTarget.webkitRequestFullscreen();
        } else if (this.uiWrapperTarget.msRequestFullscreen) { /* IE11 */
            this.uiWrapperTarget.msRequestFullscreen();
        } else if (this.uiWrapperTarget.webkitEnterFullscreen) { /* ios */
            this.uiWrapperTarget.webkitEnterFullscreen();
        }else{
            alert('Sorry your device does not support full screen mode')
        }
    }

    fullscreenchanged() {
        if (document.fullscreenElement) {
            this.uiWrapperTarget.classList.add("is-fullscreen")
            this.uiWrapperTarget.classList.remove("is-not-fullscreen")
            screen.orientation.lock("landscape")
                .catch((error) => {
                    console.error('Failed to lock landscape mode', error)
                })
        }else{
            this.uiWrapperTarget.classList.remove("is-fullscreen")
            this.uiWrapperTarget.classList.add("is-not-fullscreen")
            screen.orientation.unlock()
        }
    }

    closeFullscreen() {
        if(!document.fullscreenElement)return

        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.webkitExitFullscreen) { /* Safari */
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { /* IE11 */
            document.msExitFullscreen();
        }
    }

    updateQueueData(queue){
        this.queueTarget.innerHTML = ""
        let userIsInTheQueue = false
        queue.forEach(q => {
            const queueLog = document.createElement('div')
            let queueText = ''
            if(q.active) {
                queueText = `#${q.position}: ${q.username} is in control.`
                if(queue.length > 1) {
                    queueText = queueText + ` ${q.time_remaining} seconds left before next user.`
                }
            }else{
                queueText = `#${q.position}: ${q.username} is next.`
            }

            if(this.currentRobotData.user_id === q.user_id){
                userIsInTheQueue = true
                queueLog.style.fontWeight = "bold"
                queueText = queueText + " (this is you)"
            }

            queueLog.innerText = queueText
            this.queueTarget.appendChild(queueLog)
        })
        if(!userIsInTheQueue) {
            const queueLog = document.createElement('div')
            queueLog.innerText = `Please reconnect to the robot to join the queue.`
            this.queueTarget.prepend(queueLog)
        }
    }

    async setup() {
        if(this.doneSetup){
            return this.disconnect()
        }
        this.doneSetup = true
        this.connectButtonTarget.textContent = "Connecting..."
        try {
            const response = await fetch(`/api/v1/user/connect/${this.idValue}`)
            const json = await response.json()
            if (!json || !json.success) {
                throw new Error('Unable to get connect json')
            }
            if (!json.in_queue) {
                throw new Error('Too many users, please come back later')
            }
            if (!json.robot_id) {
                throw new Error('Missing robot')
            }
            if (!json.user_id) {
                throw new Error('Missing user')
            }
            this.currentRobotData = json

            if(this.currentRobotData.queue){
                this.updateQueueData(this.currentRobotData.queue)
            }

            // We only support a single socket host per robot, so we're not dealing with weird
            // traffic splits between multiple socket servers in different geos
            const socketHost = json.hosts.find(b => b.type === 'SOCKET')
            // const socketRtsp = json.hosts.find(b => b.type === 'RTSP') // TODO
            if (!socketHost) {
                throw new Error('Unable to find socket host')
            }
            const socketUri = `${socketHost.endpoint}/v1/stream_robot`
            const socket = io(socketUri, { transports: ["websocket"] })
            const streamUserId = socketHost.stream_user_id

            socket.on("welcome", async () => {
                console.log("Socket Authentication: starting")
                socket
                    .emitWithAck("authenticate", {
                        actor: "user",
                        robot_id: this.idValue,
                        stream_robot_id: socketHost.stream_robot_id,
                        stream_user_id: streamUserId,
                        stream_user_key: socketHost.stream_user_key
                    })
                    .then(authResult => {
                        if(!authResult || !authResult.success) {
                            console.error("Socket Authentication: failed", authResult)
                            throw Error("No success response")
                        }
                        console.log("Socket Authentication: success", authResult)
                    }).catch(e => {
                        console.error('Failed to authenticate', e)
                        socket.disconnect()
                        this.disconnect()
                    })
            })
            socket.io.on("error", (error) => {
                console.error("Socket error", error)
            })
            socket.on("disconnect", (error) => {
                console.error("Socket disconnect", error)
            })

            socket.on("robot_disconnected", () => {
                console.log("client robot disconnected")
                socket.disconnect()
                this.disconnect()
            })

            socket.on("credit_charge", (charge) => {
                // todo: UI
                console.log("Robot credit charge:" , charge)
                // SomeGobalUiManager.creditCharge(charge, "Robot Access Charge")
                // or maybe better just
                //SomeGobalUiManager.fetchLatestNotifications() and it can deal with the credit changes
            })

            socket.on("robot_queue", (queue) => {
                this.updateQueueData(queue)
            })

            this.initializedHosts.push(socket)

            json.backends.forEach(backend => {
                console.log(`Backend:`)
                console.log(` - Type: ${backend.type}`)
                console.log(` - ID: ${backend.robot_backend_id}`)
                console.log(` - User ID: ${streamUserId}`)
                console.log(` - Status: CONNECTING`)
                console.log(` `)

                let initializedBackend

                switch (backend.type) {
                    case "SOCKET_VIDEO_JPEG":
                        initializedBackend = new SocketVideoJpeg({
                            socket: socket,
                            backend: backend,
                            dom: this.backendVideosTarget
                        })
                        break;
                    case "SOCKET_VIDEO_SVG":
                        initializedBackend = new SocketVideoSvg({
                            socket: socket,
                            backend: backend,
                            dom: this.backendVideosTarget
                        })
                        break;
                    case "SOCKET_VIDEO_H264":
                        initializedBackend = new SocketVideoH264({
                            socket: socket,
                            backend: backend,
                            dom: this.backendVideosTarget
                        })
                        break;
                    case "SOCKET_TELEMETRY":
                        initializedBackend = new SocketTelemetry({
                            socket: socket,
                            backend: backend,
                            dom: this.backendTelemetriesTarget
                        })
                        break;
                    case "SOCKET_CONTROL":
                        // We can customise where controls sit
                        let thisControlDom = null
                        switch(backend.ui_slot){
                            case 'CONTROL_BOTTOM_LEFT':
                                thisControlDom = this.backendMainControlsTarget
                                break;
                            case 'CONTROL_BOTTOM_RIGHT':
                                // todo
                                break;
                            case 'AUTO':
                            case 'CONTROL_EXTRA':
                            default:
                                thisControlDom = this.backendControlsTarget
                        }
                        initializedBackend = new SocketControl({
                            socket: socket,
                            backend: backend,
                            dom: thisControlDom
                        })
                        break;
                    case "SOCKET_QUEST":
                        initializedBackend = new SocketQuest({
                            socket: socket,
                            backend: backend,
                            dom: this.backendQuestsTarget,
                            streamUserId: streamUserId
                        })
                        break;
                }

                if (initializedBackend) {
                    this.initializedBackends.push(initializedBackend)
                }
            })

            // do some fancy nested video backends:
            this.setImageOverlayStyles()

            this.connectButtonTarget.textContent = "Disconnect"
        }catch(e){
            this.connectButtonTarget.textContent = "Failed"
            console.error(e)
        }
    }

    shuffleVideos() {
        this.backendVideosTarget.appendChild(this.backendVideosTarget.childNodes[0])
        this.setImageOverlayStyles()
    }

    setImageOverlayStyles() {
        this.backendVideosTarget.childNodes.forEach((node, index) => {
            if(index > 0){
                const rightPos = ( (index-1) * 120) + 8
                node.style.right = `${rightPos}px`
                node.className = `w-[120px] h-[90px] absolute top-2 border-dashed border-2 border-indigo-600 opacity-70 group is-minimised`
            }else{
                node.style.right = 'auto'
                node.className = 'w-full h-full object-center object-cover'

            }
        })
    }
}
