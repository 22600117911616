import SocketBase from "./socket"

class SocketTelemetry extends SocketBase {
    init() {
        // We append a brand new image tag to our dom node
        this.telemetryDom = document.createElement('div')
        this.telemetryDom.className = "absolute overflow-hidden inset-0 p-2"
        this.dom.appendChild(this.telemetryDom)
    }

    terminate() {
        this.dom.removeChild(this.telemetryDom)
    }


    handleDataPacket(dataPack) {
        if(dataPack.channel && dataPack.channel === "telemetry"){
            const logEntry = document.createElement('div')
            logEntry.innerText = `${this.strip(dataPack.chunk.timestamp)}: ${this.strip(dataPack.chunk.key)}: ${this.strip(dataPack.chunk.value)}`
            this.telemetryDom.prepend(logEntry)
        }else if(dataPack.channel && dataPack.channel === "log"){
            const logEntry = document.createElement('div')
            logEntry.innerText = `${this.strip(dataPack.chunk.timestamp)}: ${this.strip(dataPack.chunk.message)}`
            this.telemetryDom.prepend(logEntry)
        }
    }
}

export default SocketTelemetry
