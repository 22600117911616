import { Controller } from "@hotwired/stimulus"
import { io } from "socket.io-client"

export default class extends Controller {
  static targets = [ "credits" ]
  static values = {
    socketHost: String,
    socketAuth: String
  }

  connect() {
    if(this.socket){
      return
    }
    const socketUri = `${this.socketHostValue}/v1/stream_webapp`
    this.socket = io(socketUri, { transports: ["websocket"] })
    this.socket.io.on("error", (error) => {
      console.error("Webapp socket error", error)
    })
    this.socket.on("welcomeWebapp", () => {
      this.socket.emit("authenticateWebapp", {
        authentication_key: this.socketAuthValue
      })
    })
    this.socket.on("credit", credit => {
      this.creditsTarget.innerText = credit
    })
    this.socket.on("notice", notice => {
      // todo: put this in a notice bar at top.
      console.log('Notice from server',notice)
    })
  }
}
