import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    document.getElementById("toggle-menu").addEventListener("click", function(){
      var mobileMenu = document.getElementById("mobile-menu-2");
      mobileMenu.classList.toggle("block");
      if (mobileMenu.classList.contains('block')) {
        mobileMenu.classList.remove("hidden");
      }else{
        mobileMenu.classList.add("hidden");
      }
    })

    const html = document.querySelector("html");
    const toggletheme = document.querySelector("#toggle-theme");
    const togglethemeicon = toggletheme?.querySelector("i");
    toggletheme?.addEventListener('click', () => {

      if (html.getAttribute('class') == "dark") {
        document.body.setAttribute("data-layout-mode", "light")
      } else {
        document.body.setAttribute("data-layout-mode", "dark")
      }

      html.classList.toggle("dark");
      const isDark = html.classList.contains('dark');
      const themeIcon = isDark ? 'moon' : 'sun';
      togglethemeicon.className = "ti ti-" + `${themeIcon}` + " top-icon";
    })


    document.querySelectorAll(".dropdown").forEach( (item) => {
      item.querySelectorAll(".dropdown-toggle").forEach((subitem) => {
        subitem.addEventListener("click", (event) => {
          subitem.classList.toggle("block");
          if (subitem.classList.contains("block") != true) {
            item.querySelector(".dropdown-menu").classList.remove("block")
            item.querySelector(".dropdown-menu").classList.add("hidden")
          } else {
            this.dismissDropdownMenu()
            item.querySelector(".dropdown-menu").classList.add("block")
            item.querySelector(".dropdown-menu").classList.remove("hidden")
            if (item.querySelector(".dropdown-menu").classList.contains("block")) {
              subitem.classList.add("block")
            } else {
              subitem.classList.remove("block")
            }
            event.stopPropagation();
          }
        });
      });
    });

    window.addEventListener('click',  (e) => {
      this.dismissDropdownMenu();
    });

  }

  dismissDropdownMenu() {
    document.querySelectorAll(".dropdown-menu").forEach(function (item) {
      item.classList.remove("block")
      item.classList.add("hidden")
    });
    document.querySelectorAll(".dropdown-toggle").forEach(function (item) {
      item.classList.remove("block")
    });
  }

}
